import Swiper from 'swiper';


import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';

const swiper = new Swiper(".swiper", {
    modules: [EffectCoverflow],
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    loop: true,
    coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
    }
});

document.addEventListener('DOMContentLoaded', function() {
    const button = document.getElementById('scroll-button');
    const target = document.getElementById('contact');

    window.addEventListener('scroll', function() {
        // ターゲット要素の位置を取得
        const targetPosition = target.getBoundingClientRect().top + (button.clientHeight / 2);
        // 現在のスクロール位置を取得
        const windowHeight = window.innerHeight;

        // ターゲット要素を過ぎたかどうかをチェック
        if (windowHeight >= targetPosition) {
            // ボタンを縮小する
            button.classList.add('shrink');
        } else {
            // ボタンを元のサイズに戻す
            button.classList.remove('shrink');
        }
    });
});